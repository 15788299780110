import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import { Auth } from '@ft/common-library'
import svgIcon from '@/components/svg-icon.vue'
import filter from '@/utils/filter.js'
import { parseQueryString } from '@/utils/common'
import '@/assets/css/index.less'
// 埋点
import stat from './utils/stat'

Vue.config.productionTip = false
const pinia = createPinia()
Vue.use(PiniaVuePlugin)
Vue.use(ElementUI, { size: 'small' })
Vue.use(filter)
Vue.component('svgIcon', svgIcon)

Vue.directive('img-async-load', function (el, binding) {
  if (binding.value && binding.oldValue !== binding.value) {
    const img = new Image(169, 220)
    img.src = binding.value
    img.onload = () => {
      if (!el.src.startsWith('blob:')) {
        el.src = img.src
      }
      img.remove()
    }
  }
})

const domainString = location.hostname.split('.')
const domain = [domainString[domainString.length - 2], domainString[domainString.length - 1]].join('.')
// token名称参数设置/?tokenName=token#/
const searchObj = parseQueryString(location.search)
const tokenName = searchObj.tokenName || 'token'
const auth = new Auth(domain, tokenName)

Vue.use(stat, { router, auth })
// 请求时添加登录信息
axios.interceptors.request.use(function (config) {
  config.headers.token = auth.getToken()
  const hash = window.location.hash
  if (hash && hash.split('/').length > 2) {
    config.headers.app = hash.split('/')[2]
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

auth.onUserIdChange = async function (userId) {
  if (!userId) {
    auth.clearToken()
    auth.userInfo = null
    window.sessionStorage.clear()
    window.location.reload()
  } else {
    // 更新权限
    await axios.get('/api/update_current_permission')
    window.location.reload()
  }
}

Vue.prototype.$auth = auth

window.global = new Vue({
  router,
  pinia,
  render: h => h(App)
}).$mount('#app')
