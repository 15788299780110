import { getTitle, getFingerPrint, getAppName, getPlatform } from './stat-route-title'

const opt = {
  url: '/knowledge/v1/event/log', // 统计接口调用地址
  appName: '商城端', // 应用名称
  userId: null, // 用户标
  referer: ''
}

const stats = {
  options: opt,
  async sendData (title, keys, event, isAsync = false) {
    // 判断输入的参数是否正确
    if (!title) {
      throw new Error('[数据埋点]  请传递 title （标题）参数')
    }
    if (!['click', 'pageview', 'close'].includes(event)) {
      throw new Error('[数据埋点]  请传递 event （事件类型）参数, 取值范围 click/pageview/close')
    }
    if (process.env.NODE_ENV === 'development') {
      console.log(`触发： ${title} ${event}`)
    }
    const data = {
      title, // 对象（页面或按钮）标题
      event, // 事件发生类型   （取值范围 click: 点击 、 page_view: 页面浏览 、 close: 关闭 等)
      appName: this.options.appName, // 应用名称 PC商城/H5个人端/H5企业端
      platform: this.options.platform, // 平台  web端/h5端/微信小程序/百度小程序/抖音小程序
      href: location.href, // 事件发生时的 URL 地址 （全）
      userId: this.options.userId, // 用户ID （可能为空）
      // clientHeight: window.screen.height, // 屏幕高度
      // clientWidth: window.screen.width, // 屏幕宽度
      referer: document ? document.referrer : '', // 来源
      userAgent: navigator.userAgent,
      fingerPrint: await getFingerPrint(),
      browseTitle: keys
    }
    const sessionId = window.sessionStorage.getItem('sessionId')
    if (sessionId) {
      data.sessionId = sessionId
    }
    if (isAsync) {
      const blob = new Blob([JSON.stringify(data)], {
        type: 'application/json; charset=UTF-8'
      })
      navigator.sendBeacon(this.options.url, blob)
    } else {
      const xhr = new XMLHttpRequest()
      xhr.open('post', this.options.url)
      xhr.setRequestHeader('content-type', 'application/json')
      xhr.onload = () => {
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText)
          window.sessionStorage.setItem('sessionId', data.result.fsessionId)
        }
      }
      xhr.send(JSON.stringify(data))
    }
  }
}

const directive = {
  // 被绑定元素插入父节点时调用 (仅保证父节点存在，但不一定已被插入文档中)。
  inserted: function (el) {
    // 聚焦元素
    el.focus()
  },
  // 只调用一次，指令第一次绑定到元素时调用。在这里可以进行一次性的初始化设置。
  bind: function (el, binding, vnode) {
    // 事件名
    const events = []
    // 标题
    const { title, keys, event } = binding.value
    // 获事件名称
    const reg = /^v-stat:\[([^\]]*)\]$/
    const matchs = reg.exec(binding.rawName)
    if (matchs && matchs.length === 2) {
      const eventStr = matchs[1].trim()
      if (eventStr) {
        eventStr.split(',').forEach(row => events.push(row))
      } else {
        events.push('click')
      }
    } else {
      events.push('click')
    }
    // 添加事件
    events.forEach((name) => {
      el.addEventListener(name, (e) => {
        vnode.context.$stats.sendData(title, keys, event)
      })
    })
  },
  unbind: function (el) {
    el.remove()
  }
}

export default {
  install (Vue, options) {
    const { router, auth } = options
    Vue.prototype.$stats = stats
    Vue.directive('stat', directive)
    // 上一个页面
    let pre = null
    window.addEventListener('beforeunload', () => {
      if (pre && pre.title) {
        stats.sendData(pre.title, null, 'close', true)
      }
    })
    router.beforeEach(async (to, from, next) => {
      stats.options.appName = getAppName(auth)
      stats.options.platform = getPlatform()
      stats.options.userId = auth.getUserId()
      if (pre && pre.title && pre.url === from.path) {
        stats.sendData(pre.title, null, 'close')
      }
      const { title, keys } = getTitle(to)
      if (title) {
        stats.sendData(title, keys, 'pageview')
        pre = {
          url: to.path,
          title
        }
      } else {
        pre = null
      }
      next()
    })
  }
}
