export function authStatus (value) {
  if (value === 0) {
    return '未认证'
  } else {
    return '已认证'
  }
}

export default {
  install (Vue) {
    Vue.filter('authStatus', authStatus)
  }
}
