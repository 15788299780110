import fingerPrint from './fingerprintjs.v4'

let fingerprintCode = null

const mapTitle = {
  '/pcClient/user/zx': {
    title: 'AI法律咨询页',
    keys: ['AI法律咨询']
  },
  '/mobile/user/zx': {
    title: 'AI法律咨询页',
    keys: ['AI法律咨询']
  }

}

export function getTitle (route) {
  const key = Object.keys(mapTitle).find((key) => {
    const reg = new RegExp('^' + key + '$', 'i')
    return reg.test(route.path)
  })

  if (key) {
    const titleObj = mapTitle[key]
    if (typeof titleObj === 'function') {
      return titleObj(route)
    } else {
      return titleObj
    }
  } else {
    return {}
  }
}

export async function getFingerPrint () {
  if (fingerprintCode) {
    return fingerprintCode
  } else {
    fingerprintCode = (await fingerPrint.load().then(fp => fp.get())).visitorId
    return fingerprintCode
  }
}

export function getPlatform () {
  // 抖音小程序 百度小程序 微信小程序 web端 h5端
  const userAgent = window.navigator.userAgent

  if ((/miniProgram/g).test(userAgent)) {
    return '微信小程序'
  } else if (/swan\//.test(userAgent) || /^webswan-/.test(userAgent)) {
    return '百度小程序'
  } else if (/ToutiaoMicroApp/.test(userAgent)) {
    return '抖音小程序'
  } else {
    const keys = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
    if (!keys.some(key => userAgent.includes(key))) {
      return 'web端'
    } else {
      return 'h5端'
    }
  }
}

export function getAppName (auth) {
  // PC商城 H5企业端  H5个人端
  const userAgent = window.navigator.userAgent
  const keys = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
  if (!keys.some(key => userAgent.includes(key))) {
    return 'PC商城'
  } else {
    const value = 'H5个人端'
    const payload = auth.getPayload()
    if (payload && payload.orgType === 2) {
      return 'H5企业端'
    }
    return value
  }
}
