import Vue from 'vue'
import VueRouter from 'vue-router'
import pcClient from '@/layout/default.vue'
import { isMobile } from '@/utils/common'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/pcClient/user/zx'
  },
  {
    path: '/pcClient',
    component: pcClient,
    redirect: '/pcClient/user/zx',
    name: 'layout',
    meta: {
      title: ''
    },
    children: [
      {
        path: ':app/zx',
        name: 'zx',
        meta: {
          title: '法图AI咨询'
        },
        component: () => import('@/webapp/zx.vue')
      }
    ]
  },
  {
    path: '/mobile',
    component: () => import('@/layout/mobile.vue'),
    redirect: '/mobile/user/zx',
    children: [
      {
        path: ':app/zx',
        name: 'mobilezx',
        meta: {
          title: '法图AI咨询'
        },
        component: () => import('@/webapp/m_ai_zx.vue')
      }
    ]
  },
  {
    path: '*',
    name: '404',
    meta: {
      title: '访问错误'
    },
    component: () => import('@/webapp/error.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (isMobile()) {
    if (to.fullPath.startsWith('/pcClient')) {
      const redirectPath = to.fullPath.replace('/pcClient', '/mobile')
      next({ path: redirectPath })
      return
    }
  } else {
    if (to.fullPath.startsWith('/mobile')) {
      const redirectPath = to.fullPath.replace('/mobile', '/pcClient')
      next({ path: redirectPath })
      return
    }
  }
  next()
})

export default router
