/**
 * ArrayBuffer合并
 * @param {ArrayBuffer|Uint8Array}buffer
 * @param {ArrayBuffer|Uint8Array}bufferAdd
 * @param {Boolean}append 尾部追加数据
 * @return {Uint8Array}
 */
export function mergeArrayBuffer (buffer, bufferAdd, append = false) {
  let view = buffer instanceof Uint8Array ? buffer : null
  let view1 = bufferAdd instanceof Uint8Array ? bufferAdd : null
  if (buffer instanceof ArrayBuffer) {
    view = new Uint8Array(buffer)
  } else if (!buffer) {
    throw new TypeError('buffer 数据类型错误')
  }
  if (bufferAdd instanceof ArrayBuffer) {
    view1 = new Uint8Array(bufferAdd)
  } else if (!bufferAdd) {
    throw new TypeError('bufferAdd 数据类型错误')
  }

  const newBuffer = new ArrayBuffer(view.length + view1.length)
  const newView = new Uint8Array(newBuffer)
  if (append) {
    newView.set(view, 0)
    newView.set(view1, view1.length)
  } else {
    newView.set(view1, 0)
    newView.set(view, view1.length)
  }
  return newView
}

export function generateUUID () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

/**
 * 节流
 * @param fn
 * @param time
 * @returns {(function(...[*]): void)|*}
 */
export function throttle (fn = Function, time = 600) {
  let oldTime = null
  return function (...params) {
    if (oldTime) { return }
    oldTime = setTimeout(() => {
      fn.call(this, ...params)
      oldTime = null
    }, time)
  }
}

export function parseQueryString (query) {
  const params = {}
  const queryString = query.substring(1)
  const pairs = queryString.split('&')
  for (let i = 0; i < pairs.length; i++) {
    const [key, value] = pairs[i].split('=')
    if (key && value) {
      params[key] = decodeURIComponent(value.replace(/\+/g, ' '))
    }
  }
  return params
}

export function closeLoading () {
  let loadDOM = document.querySelector('#apploading')
  if (loadDOM) {
    setTimeout(() => {
      document.body.removeChild(loadDOM)
      loadDOM = null
    }, 300)
  }
}

export function isMobile () {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export function isProgram () {
  const userAgent = navigator.userAgent
  if ((/miniProgram/g).test(userAgent)) {
    return 'wx'
  } else if (/swan\//.test(userAgent) || /^webswan-/.test(userAgent)) {
    return 'baidu'
  } else if (/ToutiaoMicroApp/.test(userAgent)) {
    return 'toutiao'
  }
  return false
}
