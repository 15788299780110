<template>
  <div class="nav-bar-lq flex-r">
    <div class="nav-left-btn flex-r" @click="leftClick">
      <template v-if="leftSlot === 'image'">
        <router-link to="/mobile">
          <img src="@/assets/images/logo.png" class="logo" alt="法图咨询">
        </router-link>
        <span class="line" />
        <slot name="imgTitle">
          <span>{{ title }}</span>
        </slot>
      </template>
      <template v-else>
        <svg-icon v-if="leftArrow" icon-class="left" />
        <span class="text">{{ leftText }}</span>
      </template>
    </div>
    <div v-if="leftSlot !== 'image'" class="title t-overflow">
      {{ title }}
    </div>
    <div class="nav-right-btn" @click="$emit('click-right')">
<!--      <inputSearch v-if="searchShow" :disable="!!searchPath" @click.prevent="routeSearch" />-->
      <span>{{ rightText }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  components: {
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    leftSlot: {
      type: String,
      default: 'text' // image text
    },
    leftArrow: { // 是否显示箭头
      type: Boolean,
      default: false
    },
    leftText: {
      type: String,
      default: ''
    },
    rightText: {
      type: String,
      default: ''
    },
    searchShow: {
      type: Boolean,
      default: false
    },
    searchPath: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      searchContent: ''
    }
  },
  methods: {
    routeSearch () {
      if (this.searchPath) {
        this.$router.push(this.searchPath)
      }
    },
    leftClick () {
      this.$emit('click-left')
    }
  }
}
</script>

<style scoped lang="less">
.nav-bar-lq {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 2000;
  background-color: #FFFFFF;
  align-items: center;
  height: 100px;
  box-shadow: 0px -4px 10px 6px #f1f1f1;
}
.nav-left-btn {
  position: absolute;
  align-items: center;
  /deep/ .box-svg {
    /* px-to-viewport-ignore-next */
    width: 24px;
    /* px-to-viewport-ignore-next */
    height: 24px;
    padding: 0 20px;
    color: #9c9c9c;
  }
  .text {
    font-size: 32px;
  }
}
.nav-right-btn {
  position: absolute;
  padding: 0 20px;
  right: 0;
  /deep/ .input-search {
    width: 260px;
  }
}
.logo {
  width: 148px;
  height: 56px;
  margin-left: 30px;
}
.line {
  display: block;
  width: 1px;
  height: 40px;
  margin: 0 20px;
  background: #d8d8d8;
}
.title {
  max-width: 60%;
  margin: auto;
  text-align: center;
  flex: 1 0 content;
  color: #2a2b2f;
  font-size: 32px;
}
</style>
