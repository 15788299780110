import { defineStore } from 'pinia'
import axios from 'axios'
import { isMobile, isProgram, parseQueryString } from '@/utils/common'

export const useUserStore = defineStore('user', {
  state: () => ({
    userInfo: null,
    payload: null,
    localSearchParams: parseQueryString(location.search),
    isMobile: isMobile(),
    isMiniProgram: isProgram()
  }),
  getters: {
    currentOrga () {
      if (this.payload && this.payload.orgType !== 1 && this.userInfo) {
        const orga = this.userInfo.orgas.find(row => row.id === this.payload.orgId)
        return orga ?? null
      } else {
        return null
      }
    }
  },
  actions: {
    async getUser () {
      try {
        if (this.localSearchParams.token) {
          const domainString = location.hostname.split('.')
          const domain = [domainString[domainString.length - 2], domainString[domainString.length - 1]].join('.')
          const date = new Date(new Date().getTime() + 1000 * 60 * 60 * 24)
          const tokenObj = JSON.stringify({ token: this.localSearchParams.token, remember: false, expires: date })
          const token = `${this.localSearchParams.tokenName}=${tokenObj}; Expires=${date.toUTCString()}; Domain=${domain}; Path=/`
          document.cookie = token
        }
        const { data } = await axios.get('/api/current_user')
        this.payload = data.payload
        const res = await axios.get('/operation/v1/user/current')
        this.userInfo = res.data.result
      } catch (error) {
        console.log(error)
        this.userInfo = null
      }
    }
  }
})
