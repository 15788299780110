<template>
  <div class="layout">
    <header class="header" >
      <div class="header-container flex-l-r">
        <div class="right">
          <img class="logo" src="@/assets/images/logo.png" />
          <span class="title"> {{ title }}</span>
        </div>
        <ul class="tool-btns">
          <li v-if="userStore.userInfo" class="mini-program" @mouseenter="showMinigProgramImage = true" @mouseleave="showMinigProgramImage = false">
            <svg-icon style="width: 24px;height: 24px;" icon-class="mini-program" />
            <transition name="fademini">
              <div v-show="showMinigProgramImage" class="mini-program-popup">
                <div class="mini-name flex-r">
                  <span>法图灵企业端小程序</span>
                  <span>法图灵个人端小程序</span>
                </div>
                <div>
                  <img class="mini-program-image" src="@/assets/images/mini-com.jpg">
                  <img class="mini-program-image" src="@/assets/images/mini-per.jpg">
                </div>
              </div>
            </transition>
          </li>
          <li class="btn" v-if="userStore.userInfo" >
            <el-button class="console" @click="gotoConsole">前往工作台</el-button>
          </li>
          <li class="btn userinfo"  v-if="userStore.userInfo">
            <div class="avater-info">
              <img class="avater" :src="userStore.userInfo.info.img ? userStore.userInfo.info.img : defaultAvater"/>
              <img class="auth" :src="userStore.userInfo.info.auth ?  authImg : noAuthImg "/>
            </div>

            <el-popover
              placement="bottom-start"
              popper-class="header-user-popper"
              width="310"
              trigger="click">
              <div class="user-info-item"  slot="reference">
                <span class="name">{{ userStore.userInfo.info.trusName }}</span>
                <i class="icon el-icon-arrow-down" />
              </div>
              <div class="top-menus">
                <div class="frame-popup-menu-info">
                  <div class="avater">
                    <img
                      :src="userStore.userInfo.info.img ? userStore.userInfo.info.img : defaultAvater"
                    />
                  </div>
                  <div class="name">
                    {{ userStore.userInfo.info.trusName }}
                  </div>
                  <div class="company" v-if="userStore.payload.orgType === 2">

                    <span>{{ userStore.currentOrga.name }}</span>
                    <span class="auth-info" style="margin-left:8px;line-height: 1.5;" :class="{ yes: userStore.currentOrga.authStatus }">
                            <i
                              class="iconfont icon"
                              :class="
                                userStore.currentOrga.authStatus
                                  ? 'icon-yirenzhengicon'
                                  : 'icon-weirenzhengicon'
                              "
                            />
                            {{ userStore.currentOrga.authStatus | authStatus }}
                          </span>
                  </div>
                  <div class="company" v-if="userStore.payload.orgType === 1">
                    法图号: {{ userStore.userInfo.info.account }}
                  </div>
                </div>
                <div class="frame-popup-menu-item" @click="gotoPersonInfo">
                  <i class="iconfont icon-gerenxinxiicon"/>
                  <span>个人信息</span>
                </div>
                <div class="frame-popup-menu-item" @click="gotoSwitch">
                  <i class="iconfont icon-qiehuanzhanghaoicon"/>
                  <span>切换身份</span>
                </div>
                <div class="frame-popup-menu-item" @click="logout">
                  <i class="iconfont icon-tuichuicon"/>
                  <span>退出</span>
                </div>
              </div>
            </el-popover>
          </li>
        </ul>
      </div>
      <div class="mobile-nav">
        <ft-nav-bar title="法小灵AI" left-arrow @click-left="routerBack" />
      </div>
    </header>
    <router-view></router-view>
    <div class="tool-cel flex-c">
      <el-popover
        placement="left-end"
        width="220"
        trigger="click"
      >
        <div class="kefu-content">
          <div class="t1">
            <p>服务热线</p>
            <p>400-168-5886</p>
          </div>
          <div class="ser-code">
            <p>客服企业微信</p>
            <p>微信扫码添加客服咨询</p>
            <img src="@/assets/images/server-code.png" alt="法图灵">
          </div>
        </div>
        <div slot="reference" class="f-btn flex-c">
          <svg-icon icon-class="kefu" />
          <p>客服</p>
        </div>
      </el-popover>
      <a href="https://f8vxbttf31.feishu.cn/share/base/form/shrcnjYF0NbxGvv0zoh2LLIo7fe" target="_blank" class="f-btn flex-c">
        <span class="text-btn">意见反馈</span>
      </a>
    </div>
  </div>
</template>

<script>
import defaultAvater from '@/assets/images/avater.png'
import authImg from '@/assets/images/auth.png'
import noAuthImg from '@/assets/images/auth1.png'
import { useUserStore } from '@/store/user'
import ftNavBar from '@/components/mobile/ft-nav-bar'
export default {
  name: 'pcClient',
  components: { ftNavBar },
  data () {
    return {
      userStore: useUserStore(),
      authImg,
      noAuthImg,
      defaultAvater,
      userInfo: null,
      payload: null,
      title: '',
      showMinigProgramImage: false
    }
  },
  watch: {
    '$route.meta': {
      handler (val) {
        if (val && val.title) {
          this.title = val.title
        } else {
          this.title = '法图AI咨询'
        }
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    this.userStore.getUser()
  },
  methods: {
    gotoConsole () {
      window.location.href = '//user.ftling.com'
    },
    gotoPersonInfo () {
      window.location.href = '//user.ftling.com/#/system/account/info'
    },
    gotoSwitch () {
      window.location.href = `//sso.ftling.com/?url=${encodeURIComponent(window.location.href)}#/select`
    },
    logout () {
      this.$auth.clearToken()
      window.location.href = '#/'
    },
    routerBack () {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="less">
.layout {
  position: relative;
  background-color: #f7f7fb;
  .mobile-nav {
    display: none;
  }
  .header {
    box-shadow: 0px 1px 0px 0px rgb(215, 215, 228);
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
    .header-container {
      margin: 0 auto;
      width: 1200px;
      height: 72px;
      background-color: #fff;
      align-items: center;
      .right {
        display: flex;
        flex-direction: row;
        .logo {
          height: 48px;

        }
        .title {
          font-size: 16px;
          color: #333;
          padding-left: 20px;
          margin-left: 20px;
          position: relative;
          display: flex;
          align-items: center;
          &::before {
            border-left: 1px solid #eee;
            content: "";
            height: 60px;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .tool-btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        .btn {
          cursor: pointer;
          line-height: 32px;
          position: relative;
          &+.btn {
            margin-left: 20px;
          }
          .console {
            color: #3350cb;
            font-size: 14px;
            height: 32px;
            padding: 9px 15px;
            border: 1px solid #3350cb;
            width: 102px;
            border-radius: 3px;
            background-color: #fff;
            line-height: 1;
            cursor: pointer;
            &:hover {
              background-color: #3350cb;
              color: #fff;
            }
          }
          .mini-name {
            justify-content: space-between;
            align-items: center;
            span {
              flex: 1;
              text-align: center;font-size: 14px;line-height: 32px;
            }
          }
          .mini-program-popup {
            position: absolute;
            top: 60px;
            left: 0;
            width: max-content;
            background-color: #fff;
            padding: 10px;
            box-shadow: 0 0 4px 0 #8d8d8d;
            border-radius: 3px;
            transform: translate(-50%, 10px);
            .mini-program-image {
              object-fit: contain;
              width: 250px;
              height: 290px;
            }
          }
        }
        .userinfo {
          display: flex;
          flex-direction: row;
          align-items: center;
          .avater-info {
            position: relative;
            width: 30px;
            height: 30px;
            .avater {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
            }
            .auth {
              bottom: -3px;
              height: 14px;
              position: absolute;
              right: -2px;
              width: 14px;
            }
          }
          .user-info-item {
            display: flex;
            flex-direction: row;
            align-content: center;
            margin-left: 20px;
            margin-right: 20px;
            align-items: center;
            cursor: pointer;
            .name {
              display: inline-block;
              font-size: 14px;
              max-width: 80px;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: middle;
              white-space: nowrap;
            }
            .icon {
              margin-top: 4px;
              margin-left: 10px;
            }
          }

        }
      }
    }

    .fademini-enter, .fademini-leave-to {
      opacity: 0;
    }
    .fademini-enter-active, .fademini-leave-active {
      transition: opacity 0.3s;
    }

    .mini-program {
      line-height: normal;
      position: relative;
      margin-right: 5px;
      cursor: pointer;
      .mini-name {
        justify-content: space-between;
        align-items: center;
        span {
          flex: 1;
          text-align: center;font-size: 14px;line-height: 32px;
        }
      }
      .mini-program-popup {
        position: absolute;
        top: 25px;
        left: 0;
        width: max-content;
        background-color: #fff;
        padding: 10px;
        box-shadow: 0 0 4px 0 rgba(141,141,141,0.2);
        border-radius: 3px;
        transform: translate(-50%, 10px);
        .mini-program-image {
          width: 190px;
        }
      }
    }
  }
  .tool-cel {
    position: fixed;
    right: 0;
    bottom: 5vw;
    z-index: 20;
    height: 120px;
    font-size: 12px;
    background-color: transparent;
    transform: translateX(-50%);
    .f-btn {
      position: relative;
      width: 48px;
      height: 48px;
      background: #ffffff;
      box-shadow: 0 1px 8px 0 rgba(0,0,0,0.12);
      border-radius: 10px;
      color: #2C3249;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-top: 12px;

      .box-svg {
        width: 20px;
        height: 20px;
        line-height: 20px;
      }
      p {
        margin-top: 6px;
      }
    }
  }
  footer {
    background-color: #0a0b0d;
    max-width: 100%;
    overflow: hidden;
    .f-content {
      width: 1200px;
      height: 300px;
      padding: 40px 0 30px;
      box-sizing: border-box;
      margin: 0 auto;
    }

    .tx {
      height: 199px;
      border-bottom: 1px solid #5c5f64;
      color: #ffffff;
      .logo {
        display: none;
        width: 19.7333vw;
        height: 7.4666vw;
        margin-right: 5.333vw;
      }
      span {
        display: inline-block;
        width: 172px;
      }
      .mobile {
        display: none;
      }
      .r-t {
        flex: 1;

        .chil {
          font-size: 14px;
        }

        & > div:not(:nth-child(1)) {
          margin-top: 20px;
          color: #c6cbe1;
        }
        a {
          color: #c6cbe1;
        }
      }

      .l-t {
        width: 231px;
        font-size: 12px;

        .code {
          text-align: center;
          padding-top: 20px;
          justify-content: space-between;

          img {
            width: 100px;
            height: 100px;
          }

          p {
            margin-top: 8px;
          }
        }
      }
    }

    .ba {
      font-size: 14px;
      color: #c6cbe1;
      margin-top: 24px;
      text-align: center;
      a {
        color: inherit;
      }
    }

    @media screen and (max-width: 750px) {
      .f-content {
        overflow: hidden;
        height: 18.667vw;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        align-items: center;
        box-sizing: border-box;
        padding: 0;
        width: 100%;
        .tx {
          height: 100%;
          border: none;
          align-items: center;
        }
        .mobile {
          display: flex;
          li {
            color: #fff;
            position: relative;
            font-size: 3.4666vw;
            & + li {
              margin-left: 8.133vw;
            }
            & + li::after {
              content: '';
              position: absolute;
              top: 50%;
              left: -4vw;
              width: 1px;
              height: 4vw;
              transform: translateY(-50%);
              background: #d8d8d8;
            }
          }
        }
        .logo {
          display: inline-block;
          margin-left: 4vw;
          margin-right: 9vw;
          width: 24vw;
          height: 8.533vw;
        }
        .l-t, .r-t {
          display: none;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .layout {
    min-height: 100vh;
    width: 100%;
  }
 .header {
   .header-container {
     display: none;
   }
   .mobile-nav {
     height: 13.333vw;
     display: block;
   }
  }
 .tool-cel {
   display: none;
 }
}
</style>
